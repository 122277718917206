<template>
  <div class="card card-custom card-stretch ">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Sites registrados
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          Lista de todos os sites cadastrados
        </span>
      </h3>
      <div class="card-toolbar" v-if="list.totalDocs">
        <b-button
          class="btn btn-success font-weight-bolder font-size-sm"
          v-b-modal.account-modal
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg
              src="media/svg/icons/Communication/Add-user.svg"
            /> </span
          >Adicionar site</b-button
        >

        <modalAccount
          :totalDocs="list.totalDocs"
          :account="account"
        ></modalAccount>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr class="text-left">
              <th class="pr-0" style="width: 0px"></th>
              <th style="min-width: 100px" class="text-left pl-0">Site</th>

              <th style="min-width: 100px" class="text-center">Cookies</th>
              <th style="min-width: 150px" class="text-center">
                Visualizações
              </th>
              <th style="min-width: 150px" class="text-center">
                Consentimentos
              </th>
              <!-- <th style="min-width: 150px">Opt-outs</th> -->
              <!-- <th style="min-width: 100px">Banner</th> -->
              <th style="min-width: 150px" class="text-center">Progresso</th>
              <th class="pr-0 text-center" style="min-width: 100px">Ações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list.docs">
              <tr v-bind:key="i">
                <td class="pr-0">
                  <div class="symbol symbol-50 symbol-light mt-1">
                    <span
                      class="initials symbol-label"
                      style="background:#fff; margin-right:10px;"
                    >
                      {{ initials(item.name || item.website) }}
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <router-link
                    :to="`sites/${item._id}/details`"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ item.name }}
                  </router-link>
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ item.website }}</span
                  >
                </td>

                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.stats.cookies }}
                  </span>
                  <!-- <span class="text-muted font-weight-bold">
                    {{ item.stats.views }}
                  </span> -->
                </td>
                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.stats.views }}
                  </span>
                  <!-- <span class="text-muted font-weight-bold">
                    {{ item.stats.uniques }}
                  </span> -->
                </td>

                <td class="text-center">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.stats.consents }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    <template
                      v-if="item.stats.consents > 0 || item.stats.views > 0"
                    >
                      {{
                        (
                          (item.stats.consents / item.stats.views) *
                          100
                        ).toFixed(2)
                      }}
                      %
                    </template>
                    <template v-else>
                      0.00%
                    </template>
                  </span>
                </td>

                <!-- <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.stats.optouts }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    <template
                      v-if="item.stats.consents > 0 || item.stats.views > 0"
                    >
                      {{
                        ((item.stats.optouts / item.stats.views) * 100).toFixed(
                          2
                        )
                      }}
                      %
                    </template>
                    <template v-else>
                      0.00%
                    </template>
                  </span>
                </td> -->

                <!-- <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.consent.configs.type }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ item.consent.configs.position }}
                  </span>
                </td> -->
                <td>
                  <StepsProgress :item="item" />
                </td>
                <td
                  class="pr-0 text-center d-flex justify-content-center align-itens-center"
                >
                  <!-- <router-link
                    :to="`sites/${item._id}/consent`"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="media/svg/icons/Code/Code.svg" />
                    </span>
                  </router-link> -->
                  <!-- 
                  <b-button
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    v-b-modal.account-code
                    ><span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="media/svg/icons/Code/Code.svg" /> </span
                  ></b-button> -->

                  <modalCode :account="item"></modalCode>

                  <router-link
                    :to="`sites/${item._id}/details`"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    :content="
                      `<p><h6>Detalhes do site</h6><i>${item.website}</i></p>`
                    "
                    v-tippy
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <!-- <inline-svg
                        src="media/svg/icons/General/Visible.svg"
                        title=""
                        alt=""
                        content="<p><h6>Em processamento</h6> Estamos buscando todos os cookies do seu site.</p>"
                        v-tippy
                      /> -->
                      <i class="icon far fa-eye"></i>

                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>

    <pagination :pagination="list" />
    <!--end::Body-->
  </div>
</template>

<script>
import modalAccount from "../modal/Index";
import modalCode from "../modal/code";
import ApiService from "@/core/services/api.service";
import pagination from "@/view/content/widgets/pagination.vue";
import StepsProgress from "@/view/content/widgets/steps-progress/index.vue";

export default {
  name: "sites-list",
  data() {
    return {
      account: {
        owner: this.$store.state.auth.user._id,
        name: "",
        title: "",
        website: ""
      },
      list: {},
      checked: false
    };
  },
  components: {
    modalAccount,
    modalCode,
    pagination,
    StepsProgress
  },
  computed: {
    page() {
      return this.list.page || 1;
    },
    limit() {
      return this.list.limit || 10;
    },
    steps() {
      return this.$t("STEPS");
    }
  },
  mounted() {
    this.getSites();
  },
  watch: {
    page() {
      this.getSites();
    }
  },
  methods: {
    getSites() {
      ApiService.setHeader();
      let userId = this.$route.query.user
        ? this.$route.query.user
        : this.$store.state.auth.user._id;
      // let userId = "60b7f92b149a0b001b404577";
      ApiService.query(
        `users/${userId}/accounts/list?page=${this.page}&limit=${this.limit}`
      )
        .then(({ data }) => {
          //console.log("USER", data);
          // this.processList(data);
          this.list = data;
        })
        .catch(() => {
          //console.log("USER ERROR", response);
        });
    },
    getTotalCookies(_id) {
      return ApiService.query(`accountCookies/${_id}/count`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          //console.log("ERROR", response);
          return false;
        });
    },
    setCheck(checked) {
      this.checked = checked;
    },
    setStats(element) {
      element.stats = {
        cookies: 0,
        views: 0,
        consents: 0,
        optouts: 0
      };
      element.configs = {
        title: "Lorem ipsum TITLE",
        description: "Lorem ipsum DESC",
        theme: "default",
        position: "bottom-left",
        privacy: "#privacy.html",
        terms: "#terms.html",
        buttons: {
          accepted: "Aceitar",
          options: "Preferencias"
        }
      };
      return element;
    },
    initials(item) {
      return item
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    }
  }
};
</script>
<style scoped>
/* i.text-success {
  color:#1bc5bd !important;
} */
i.text-sucess {
  color: #1bc5bd;
}
</style>
