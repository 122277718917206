<template>
  <div
    class="d-flex justify-content-center mt-5 align-items-center flex-wrap"
    v-if="hasPages"
  >
    <div class="d-flex flex-wrap py-2 mr-3">
      <button
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        @click="first()"
      >
        <i class="ki ki-bold-double-arrow-back icon-xs"></i>
      </button>
      <button
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        @click="prev()"
        :disabled="!this.pagination.hasPrevPage"
      >
        <i class="ki ki-bold-arrow-back icon-xs"></i>
      </button>
      <template v-if="this.pagination.totalPages">
        <template v-for="page in nPages">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            :class="{ active: isActive(page) }"
            :key="page"
            @click="setPage(page)"
            v-if="pageActive(page)"
          >
            {{ page }}
          </button>
        </template>
      </template>

      <button
        @click="next()"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
        :disabled="!this.pagination.hasNextPage"
      >
        <i class="ki ki-bold-arrow-next icon-xs"></i>
      </button>
      <button
        @click="last()"
        class="btn btn-icon btn-sm btn-light-primary mr-2 my-1"
      >
        <i class="ki ki-bold-double-arrow-next icon-xs"></i>
      </button>
    </div>
    <!-- <div class="d-flex align-items-center py-3"> -->
    <!-- <div class="d-flex align-items-center">
        <div class="mr-2 text-muted">Loading...</div>
        <div class="spinner spinner-primary mr-10"></div>
      </div> -->

    <!-- <template v-if="pagination.limit">
        <select
          v-model="pagination.limit"
          class="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
          style="width: 75px;"
        >
          <option value="1">1</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span class="text-muted"
          >{{ pagination.limit }} de {{ pagination.totalDocs }} registros</span
        >
      </template> -->
    <!-- </div> -->
    <!-- {{ pagination }} -->

    <!-- hasNextPage: false,
hasPrevPage: false,
page: 1,
limit: 10,
nextPage: 2,
pagingCounter: 1,
prevPage: null,
totalDocs: 29,
totalPages: 3 -->
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: { type: Object, default: () => {} }
  },
  computed: {
    nPages() {
      let pages = [...Array(this.pagination.totalPages).keys()];
      pages.shift();
      pages.push(pages.length + 1);
      return pages;
    },
    start() {
      if (this.pagination.page >= this.pagination.totalPages - 2)
        return this.pagination.totalPages - 4;
      return this.pagination.page - 2;
    },
    end() {
      if (this.pagination.page <= 2) return 5;
      return this.pagination.page + 2;
    },
    hasPages() {
      return this.pagination.totalPages > 1;
    }
  },
  methods: {
    pageActive(page) {
      return page >= this.start && page <= this.end;
    },
    isActive(page) {
      return page === this.pagination.page;
    },
    first() {
      this.$set(this.pagination, "page", 1);
    },
    last() {
      this.$set(this.pagination, "page", this.pagination.totalPages);
    },
    prev() {
      // if (this.pagination.hasPrevPage) {
      this.$set(this.pagination, "page", this.pagination.prevPage);
      // }
    },
    next() {
      // if (this.pagination.hasNextPage) {
      this.$set(this.pagination, "page", this.pagination.nextPage);
      // }
    },
    setPage(page) {
      this.$set(this.pagination, "page", page);
    }
  }
};
</script>

<style></style>
