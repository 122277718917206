<template>
  <div class="d-flex flex-column w-100 mr-2">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <i
        class="icon fas fa-crosshairs  mr-5"
        :class="stepsInfos['crawler'][item.steps.crawler].class"
        :content="stepsInfos['crawler'][item.steps.crawler].tip"
        v-tippy
      >
      </i>

      <router-link :to="`/sites/${item._id}/cookies`">
        <template v-if="!item.steps.crawler">
          <i
            class="icon fas fa-th-list pulse pulse-primary  mr-5"
            :class="stepsInfos['cookies']['false'].crawler.class"
            :content="stepsInfos['cookies']['false'].crawler.tip"
            v-tippy
          ></i>
        </template>
        <template v-else>
          <i
            class="icon fas fa-th-list pulse pulse-primary  mr-5"
            :class="stepsInfos['cookies'][item.steps.cookies].class"
            :content="stepsInfos['cookies'][item.steps.cookies].tip"
            v-tippy
          ></i>
        </template>
      </router-link>
      <router-link :to="`/sites/${item._id}/banner`">
        <i
          class="icon fas fa-palette mr-5"
          :class="stepsInfos['banner'][item.steps.banner].class"
          :content="stepsInfos['banner'][item.steps.banner].tip"
          v-tippy
        ></i>
      </router-link>
      <i
        class="icon fas fas fa-code mr-5"
        :class="stepsInfos['js'][item.steps.js].class"
        :content="stepsInfos['js'][item.steps.js].tip"
        v-tippy
      ></i>
    </div>
    <div class="progress progress-xs w-100">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        :style="{ width: `${calcPercent(item.steps)}%` }"
        :aria-valuenow="calcPercent(item.steps)"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsProgress",

  props: {
    item: { type: Object, required: true }
  },

  data() {
    return {
      stepsInfos: {
        crawler: {
          false: {
            class: "text-info",
            tip:
              "<p><h6>Em processamento</h6> Estamos buscando todos os cookies do seu site.</p>"
          },
          true: {
            class: "text-sucess",
            tip:
              "<p><h6>Processado</h6> Seus cookies foram identificados com sucesso!</p>"
          }
        },
        cookies: {
          false: {
            class: "text-warning",
            crawler: {
              class: "text-info",
              tip:
                "<p><h6>Aguarde</h6> Em breve seus cookies serão listados para que você possa edita-los.</p>"
            },
            tip:
              "<p><h6>Configure</h6> Você precisa configurar seus cookies para gerar um versão inicial.</p>"
          },
          true: {
            class: "text-sucess",
            tip:
              "<p><h6>Cookies Configurados</h6> Todos os seus cookies estão com as suas categorias definidas.</p>"
          }
        },
        banner: {
          false: {
            class: "text-warning",
            tip:
              "<p><h6>Banner Configurado</h6> Configurado com a versão padrão.</p>"
          },
          true: {
            class: "text-sucess",
            tip:
              "<p><h6>Banner Configurado</h6> Banner configurado com sucesso.</p>"
          }
        },
        js: {
          false: {
            class: "text-warning",
            tip:
              "<p><h6>Instale nossa tag</h6> Ainda não identificamos nossa tag no seu site.</p>"
          },
          true: {
            class: "text-sucess",
            tip:
              "<p><h6>Tag Instalada</h6> Tudo certo! Nossa tag foi instalada corretamente.</p>"
          }
        }
      }
    };
  },

  methods: {
    calcPercent(steps) {
      let keys = Object.keys(steps);
      let sum = 100 / keys.length;
      let total = 0;
      keys.forEach(step => {
        if (steps[step]) total = total + sum;
      });
      return total;
    }
  }
};
</script>

<style scoped>
i.text-sucess {
  color: #1bc5bd;
}
</style>
